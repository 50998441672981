import React from 'react';
import ReactDOM from 'react-dom';
import { VscAdd } from "react-icons/vsc";
import './index.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import update from 'immutability-helper';
import Composition from './composition'

function Button(props) {
    return (
        <button className="button" onClick={props.onClick} title={props.label}><VscAdd /></button>
    );
}

function Spacer(props) {
    return props.alignment == 'v' ? <div style={'height:' + props.size + 'px'}></div> : <div style={'width:' + props.size + 'px'}></div>
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

class Editor extends React.Component {

    constructor(props) {
        super(props);

        const dummyIngredients = [
            {id: 0, name: "Hackfleisch"},
            {id: 1, name: "Paprika"},
            {id: 2, name: "Sahne"},
            {id: 3, name: "Schnittlauch"}
        ]


        this.state = {
            templates: [],
            compositions: [],
            ingredients: dummyIngredients,
            tools: [],
            editTemplateId: null,
            editCompositionId: null,
            editIngredientId: null,
            editToolId: null,
            templateFilterStr: "",
            compositionFilterStr: "",
            ingredientsFilterStr: "",
            toolsFilterStr: ""
        }

        this.onDragEnd = this.onDragEnd.bind(this)
        this.keydownHandler = this.keydownHandler.bind(this)
    }

    loadData() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EC66BBC54FD7792A8262F8587BBC7C6D28ECA66D3DCC23793969FBAC16");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://receiptdb.moontec.de/request.php", requestOptions)
            .then(response => response.json())
            .then(result => this.setState(result))
            .catch(error => console.log('error', error));
    }

    saveData() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EC66BBC54FD7792A8262F8587BBC7C6D28ECA66D3DCC23793969FBAC16");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(this.state);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://receiptdb.moontec.de/request.php", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    keydownHandler(e){
        if ((e.metaKey || e.ctrlKey) && e.key === 's') {
            e.preventDefault()
            console.log('save data!', JSON.stringify(this.state))
            this.saveData();
        }
    }

    componentDidMount(){

        // load
       this.loadData()
            
        //
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.keydownHandler);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const editCompositionIndex = this.state.compositions.findIndex(c => c.id === this.state.editCompositionId)
        var editComposition = {...this.state.compositions[editCompositionIndex]}
        const instructions = reorder(
          editComposition.instructions,
          result.source.index,
          result.destination.index
        );

        editComposition.instructions = instructions
        const compositions = [...this.state.compositions]
        compositions[editCompositionIndex] = editComposition
    
        //this.setState(update(this.state, {compositions: {instructions: {$set: instructions}}}));
        this.setState({compositions: compositions})
      }

    createNewTemplate() {
        const newTemplate = {
            id: this.state.templates.length,
            name: "New Template",
            instructions: []
        }

        //
        var templates = this.state.templates.slice()
        templates.push(newTemplate)
        this.setState({templates: templates})
    }

    createNewComposition() {
        /*
        {id: 0, name: "InstA", text: "do this, do that", ingredients: [], active: true, duration: 10},
        {id: 1, name: "InsB", text: "then take thaat for this", ingredients: [], active: true, duration: 20},
        {id: 2, name: "InsC", text: "finally wait", ingredients: [], active: false, duration: 5}
        */
        const newComposition = {
            id: this.state.compositions.length,
            name: "New Composition",
            instructions: []
        }

        //
        var compositions = this.state.compositions.slice()
        compositions.push(newComposition)
        this.setState({compositions: compositions})
    }

    createNewIngredient() {
        const newIngredient = {
            id: this.state.ingredients.length,
            name: "Neue Zutat"
        }
        this.setState(update(this.state, {ingredients: {$push: [newIngredient]}}))
    }

    editTemplate(templateId) {
        this.setState({editTemplateId: templateId})
    }

    stopEditingTemplate() {
        this.setState({editTemplateId: null})
    }

    editComposition(compositionId) {
        this.setState({editCompositionId: compositionId})
    }

    stopEditingComposition() {
        this.setState({editCompositionId: null})
    }

    editIngredient(ingredientId) {
        this.setState({editIngredientId: ingredientId})
    }

    stopEditingIngredient() {
        this.setState({editIngredientId: null})
    }

    renderTemplatesContent() {
        if (this.state.editTemplateId !== null) {
            const editTemplateIndex = this.state.templates.findIndex(t => t.id === this.state.editTemplateId) 
            let editTemplate = this.state.templates[editTemplateIndex]
            return (
                <div className="fields">
                    {/* name */}
                    <input className="textField" type="text" value={editTemplate.name} onChange={(e) => {editTemplate.name = e.target.value; this.setState({templates: [...this.state.templates.slice(0, editTemplateIndex), editTemplate, ...this.state.templates.slice(editTemplateIndex + 1)]})}} />
                    
                    {/* instructions */}
                    <h2>Instruktionen</h2>
                    <div className="instructions">
                        {editTemplate.instructions.map((instruction, index) => (
                            <div className="instruction">
                                <div className="options">
                                    <p>Nr. {index + 1}</p>
                                </div>
                                <textarea value={instruction.text} onChange={e => {this.editTemplateInstructionText.bind(this, editTemplateIndex, index, e.target.value)();console.log('instruction text changed to: ', e.target.value)}} />
                                <div className="ingredients">
                                    {instruction.compositions.map(c => <p key={c.id}><span>{c.amount} Portionen</span><span>{this.state.compositions.find(c_ => c_.id === c.compositionId).name}</span></p>)}
                                </div>
                            </div>
                        ))}
                        <Button label="Neue Instruktion" onClick={this.createNewTemplateInstruction.bind(this, editTemplateIndex)} />
                    </div>
                </div>
            )
        } else {
            const f = this.state.templateFilterStr.toLowerCase()
            const templates = this.state.templates.filter(t => t.name.toLowerCase().includes(f)).map(t => <Template key={t.id} name={t.name} onClick={this.editTemplate.bind(this, t.id)}/>)
            return (
                <>
                    <div className="list">
                        <input className="textField" value={f} onChange={e => this.setState({templateFilterStr: e.target.value})} placeholder="Suchen"/>
                        {templates}
                    </div>
                    <Button label="Hinzufügen" onClick={this.createNewTemplate.bind(this)}/>
                </>
            )
        }
    }

    createNewTemplateInstruction(templateIndex) {
        const newInstruction = {
            id: this.state.templates[templateIndex].instructions.length,
            name: "",
            text: "",
            compositions: [],
            active: false,
            duration: 0
        }
        this.setState(update(this.state, {templates: {[templateIndex]: {instructions: {$push: [newInstruction]}}}}))
    }

    editTemplateInstructionText(templateIndex, instructionIndex, text) {
        //this.setState(update(this.state, {compositions: {[compositionIndex]: {instructions: {[instructionIndex]: {text: {$set: text}}}}}}))

        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/matchAll
        // example: '20g Butter und 10 Milliliter Sahne vermischen'
        const regexp = /([\d\.]+):([\wäöüÄÖÜß]+)/g;
        const matches = [...text.matchAll(regexp)];
        console.log(matches)

        var compositions = []
        matches.forEach( match => {
            const [,amount, name] = match
            const composition = this.state.compositions.find(c => c.name.toLowerCase() === name.toLowerCase())
            console.log("editTemplateInstructionText: composition/s: ", composition, compositions, this.state.compositions)
            if (composition) {
                compositions.push({
                    compositionId: composition.id,
                    amount: amount
                })
                console.log("new composition: ", composition)
            }
        });

        this.setState(update(this.state, {templates: {[templateIndex]: {instructions: {[instructionIndex]: {compositions: {$set: compositions}, text: {$set: text}}}}}}))
    }



    createNewCompositionInstruction(compositionIndex) {
        const newInstruction = {
            id: this.state.compositions[compositionIndex].instructions.length,
            name: "",
            text: "",
            ingredients: [],
            active: false,
            duration: 0
        }
        this.setState(update(this.state, {compositions: {[compositionIndex]: {instructions: {$push: [newInstruction]}}}}))
    }

    // TODO: rename to "editKompositionInstructionText"
    editItemText(compositionIndex, instructionIndex, text) {
        //this.setState(update(this.state, {compositions: {[compositionIndex]: {instructions: {[instructionIndex]: {text: {$set: text}}}}}}))

        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/matchAll
        // example: '20g Butter und 10 Milliliter Sahne vermischen'
        const regexp = /([\d]+)\s?(g|ml|Gramm|Milliliter|Stück|Dose|Prise|Esslöffel|Teelöffel|Messerspitze|EL|TL|MSP|Stk.)\s+([\wäöüÄÖÜß]+)/g;
        const array = [...text.matchAll(regexp)];
        console.log(array)

        const ingredientsNames = this.state.ingredients.map(i => i.name)
        var ingredients = []
        array.forEach( i => {
            const [text, amount, unit, name] = i
            const ingredient = this.state.ingredients.find(i => i.name.toLowerCase() === name.toLowerCase())
            console.log("ingredient: ", ingredient)
            if (ingredient) {
                ingredients.push({
                    ingredientId: ingredient.id,
                    amount: amount,
                    unit: unit
                })
                console.log("new ingredients: ", ingredients)
            }
        });

        this.setState(update(this.state, {compositions: {[compositionIndex]: {instructions: {[instructionIndex]: {ingredients: {$set: ingredients}, text: {$set: text}}}}}}))
    }

    // TODO: rename to "editKompositionInstructionActive"
    editInstructionActive(compositionIndex, instructionIndex, active) {
        console.log('change active', active)
        this.setState(update(this.state, {compositions: {[compositionIndex]: {instructions: {[instructionIndex]: {active: {$set: active}}}}}}))
    }

    // TODO: rename to "editKompositionInstructionDuration"
    editInstructionDuration(compositionIndex, instructionIndex, duration) {
        console.log('change active', duration)
        this.setState(update(this.state, {compositions: {[compositionIndex]: {instructions: {[instructionIndex]: {duration: {$set: duration}}}}}}))
    }

    renderCompositionsContent(){
        if (this.state.editCompositionId !== null) {
            const editCompositionIndex = this.state.compositions.findIndex(c => c.id === this.state.editCompositionId) 
            let editComposition = this.state.compositions[editCompositionIndex]
            return (
                <div className="fields">
                    {/* name  */}
                    <input className="textField" type="text" value={editComposition.name} onChange={e => {editComposition.name = e.target.value; this.setState({compositions: [...this.state.compositions.slice(0, editCompositionIndex), editComposition, ...this.state.compositions.slice(editCompositionIndex + 1)]})}} />
                    
                    {/* instructions */}
                    <h2>Instruktionen</h2>
                    <div className="instructions">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} >
                                {editComposition.instructions.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <div className="instruction">
                                                <div className="options">
                                                    <p>Nr. {index + 1}</p>
                                                    <p onClick={this.editInstructionActive.bind(this, editCompositionIndex, index, !item.active)}>{item.active ? 'active' : 'passive'}</p>
                                                    <p><input value={item.duration} onChange={e => this.editInstructionDuration(editCompositionIndex, index, parseInt(e.target.value))} /> min</p>
                                                </div>
                                                <textarea value={item.text} onChange={e => {this.editItemText.bind(this, editCompositionIndex, index, e.target.value)();console.log('instruction text changed to: ', e.target.value)}} />
                                                <div className="ingredients">
                                                    {item.ingredients.map(i => <p key={i.id}><span>{i.amount}</span><span>{i.unit}</span><span>{this.state.ingredients.find(j => j.id === i.ingredientId).name}</span></p>)}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>

                        <Button label="Neue Instruktion" onClick={this.createNewCompositionInstruction.bind(this, editCompositionIndex)} />
                        <Button label="Löschen" onClick="" />
                    </div>

                </div>
            )
        } else {
            const f = this.state.compositionFilterStr.toLowerCase()
            const compositions = this.state.compositions.filter(c => c.name.toLowerCase().includes(f)).map(c => <Template key={c.id} name={c.name} onClick={this.editComposition.bind(this, c.id)} /> )
            return (
                <>
                    <div className="list">
                        <input className="textField" value={f} onChange={e => this.setState({compositionFilterStr: e.target.value})} placeholder="Suchen"/>
                        {compositions}
                    </div>
                    <Button label="Neue Komposition" onClick={this.createNewComposition.bind(this)}/>
                </>
            )
        }
    }

    renderIngredientsContent() {
        if (this.state.editIngredientId !== null) {
            const editIngredientIndex = this.state.ingredients.findIndex(i => i.id === this.state.editIngredientId) 
            let editIngredient = this.state.ingredients[editIngredientIndex]
            return (
                <div className="fields">
                    {/* name  */}
                    <input className="textField" type="text" value={editIngredient.name} onChange={e => {editIngredient.name = e.target.value; this.setState(update(this.state, {ingredients: {[editIngredientIndex]: {$set: editIngredient}}})); }} />
                </div>
            )  
        } else {
            const f = this.state.ingredientsFilterStr.toLowerCase()
            const ingredients = this.state.ingredients.filter(i => i.name.toLowerCase().includes(f)).map(i => <Template key={i.id} name={i.name} onClick={this.editIngredient.bind(this, i.id)}></Template>)
            return (
                <>
                    <div className="list">
                        <input className="textField" value={f} onChange={e => this.setState({ingredientsFilterStr: e.target.value})} placeholder="Suchen"/>
                        {ingredients}
                    </div>
                    <Button label="Neue Zutat" onClick={this.createNewIngredient.bind(this)}/>
                </>
            )
        }
    }

    render() {
        const editTemplateName = this.state.editTemplateId !== null ? this.state.templates.find(t => t.id === this.state.editTemplateId).name : null
        const editCompositionName = this.state.editCompositionId !== null ? this.state.compositions.find(c => c.id === this.state.editCompositionId).name : null
        const editIngredientName = this.state.editIngredientId !== null ? this.state.ingredients.find(i => i.id == this.state.editIngredientId).name : null
        //const ingredients = this.state.ingredients.map(i => <Template key={i.id} name={i.name} onClick={(e) => console.log('ing clicked: ', e, i, i.id)}></Template>)
        return (
            <>
                <Column title="Templates" editing={editTemplateName} onTitleClick={this.stopEditingTemplate.bind(this)}>
                    {this.renderTemplatesContent()}
                </Column>
                <Column title="Kompositionen" editing={editCompositionName} onTitleClick={this.stopEditingComposition.bind(this)}>
                    {this.renderCompositionsContent()}
                </Column>
                <Column title="Zutaten" editing={editIngredientName} onTitleClick={this.stopEditingIngredient.bind(this)}>
                    {this.renderIngredientsContent()}
                </Column>
            </>
        );
    }
}

class Column extends React.Component {
    render() {
        return (
            <div className="column">
                <div className="title">
                    <p onClick={this.props.onTitleClick}>{this.props.title}</p>
                    {this.props.editing && <p>{this.props.editing}</p>}
                </div>

                <div className="content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

class Template extends React.Component {
    render() {
        return (
            <div className="template" onClick={this.props.onClick}>
                <p className="name">{this.props.name}</p>
            </div>
        )
    }
}

/** remnder **/
ReactDOM.render(
    <Editor text="Test01EditorWithReact" />,
    document.getElementById('editor')
);