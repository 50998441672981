import React, { Component } from 'react';

class Composition extends Component {

  render() {
    return(
      <p>Click on an emoji to view the emoji short name.</p>
    )
  }

}

export default Composition